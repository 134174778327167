.form-group{
  label{
    font-size: 0.8rem;
    color: black;
  }
  input[type="text"] {
    font-size: 16px !important;
  }
  input, select{
    border: none;
    background: $flinkit-secondary-bg-color !important;
    font-weight: bold;
    padding: 20px 15px;
  }
  select{
    padding: 10px;
    height: 45px;
  }
}

.flinkit-toggle-switch{
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  border: 3px solid $flinkit-base-color;
  padding: 3px;
  .switch-value{
    font-weight: bold;
    font-size: 0.8rem;
    width: 50%;
    display: inline-block;
    text-align: center;
    padding: 10px;
    color: $flinkit-base-color;
    &.active{
      background: $flinkit-base-color;
      color: white;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
    }
  }
}
