.fi-game-history-panel, .fi-chat-panel{
  .header{
    background-color: $flinkit-base-color !important;
    text-align: center;
    line-height: 45px;
    color: white !important;
    height: 45px;
    width: 100%;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .body{
    padding: 1rem;
    min-height: 200px;
    max-height: 350px;
    background: #f8f8f8;
    overflow: auto;
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    &.maxHeight{
      height: 100% !important;
      max-height: 100% !important;
    }

    .messages {
      display: flex;
      flex-direction: column;
    }

    .message {
      border-radius: 20px;
      padding: 8px 15px;
      margin-top: 5px;
      margin-bottom: 5px;
      display: inline-flex;
      font-size: 0.8rem;
      font-weight: bold;
    }

    .yours {
      align-items: flex-start;
    }

    .yours .message {
      background-color: #eee !important;
      position: relative;
      &.success{
        background: #1AFCAE;
        color: white;
      }
      &.danger{
        background: #FF006C;
        color: white;
      }
    }

    .mine .message {
      color: white;
      background: $flinkit-base-color !important;
      background-attachment: fixed;
      position: relative;
    }

  }
}
