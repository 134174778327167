.flinkint-btn{
  text-align: center;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 10px 10px !important;
  font-weight: bold;
  border:3px solid $flinkit-base-color;
  &.fill-btn{
    position: relative;
    height: 50px;
    border:none !important;
    &.time-btn{
      background: #ff5f60;
    }
    &.warning{
      background: #ff5f60;
    }
    .content{
      position: absolute;
      width: 100%;
      height: 50px;
      line-height: 50px;
      left: 0px;
      top:0px;
      text-align: center;
    }
    .fill{
      position: absolute;
      top:0px;
      left: 0px;
      background: $flinkit-base-color !important;
      height: 100%;
      transition: width 1s;
      -webkit-border-top-left-radius: 30px;
      -webkit-border-bottom-left-radius: 30px;
      -moz-border-radius-topleft: 30px;
      -moz-border-radius-bottomleft: 30px;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }
  }
  &.white{
    background: white;
    border: 3px solid white;
    color: $flinkit-base-color;
    &.outline{
      color: white;
      border: 3px solid white;
      background: transparent;
    }
    &:disabled{
      opacity: 0.7 !important;
    }
  }
  &.lightblue{
    background-color: #77edff;
    border: 3px solid #77edff;
    color: black;
  }
  &.lightblue{
   
    &.outline{
      color: #77edff;
      border: 3px solid #77edff;
      background: transparent;
    }
  }
  &.danger{
    background: #FF006C;
    border-color: #FF006C;
    color: white;
  }
  &.success{
    background-color: #03a9f4;
    border: none;
    color: white;
  }
  &.primary{
    background: $flinkit-base-color;
    &.alt{
      background: #7962a9;
    }
    color: white;
    &:disabled{
      background-color: $flinkit-base-color-activated;
      border-color: $flinkit-base-color-activated;
    }
  }

  &.success{
    background: rgb(0, 200, 0);
    color: white;
    border: 2px solid rgb(0, 200, 0);
    &:disabled{
      background-color: $flinkit-base-color-activated;
      border-color: $flinkit-base-color-activated;
    }
  }

  &.outline{
    background: white;
    color: $flinkit-base-color;
    border:3px solid $flinkit-base-color;
  }
}
.btn-outline-primary:hover{
  background: transparent;
}
.fi-segmented-control{
  width: 100%;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: white;
  display: flex;
  padding: 5px;
  &.gray{
    background: #efefef;
  }
  button{
    border: none;
    background: transparent;
    width: 100%;
    -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 10px;
    &.active{
      background: $flinkit-base-color;
      color: white;
    }
  }
}

.fi-toggle-button{
  width: 100%;
  padding: 5px;
  height: 50px;
  color: $flinkit-base-color;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: white;
  div{
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    text-align: center;
    line-height: 43px;
    &.active{
      background: $flinkit-base-color;
      color: white;
    }
  }
}
