.bottom-pos-0{
  bottom:0px;
  z-index: 99;
}
.img-fluid-80{
  max-width: 85% !important;
}
.img-fluid-30{
  max-width: 30% !important;
}
.w-85{
  width: 85% !important;
}
.mt-5-fx{
  margin-top: 5rem !important;
}
.fi-pill{
  background-color: #b87671 !important;
  display: inline-flex;
  padding: 10px 20px;
  border-radius: 30px;
  color: white;
  font-size:0.9rem;
  font-weight:bold;
}

.spin {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.fl-hoverer{
  &:hover{
    cursor: pointer;
    opacity: 0.8;
  }
}

/*Circle helpers*/
.fi-before-circle{
  &::before{
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
}
/*End of Circle helpers*/
/*Ruler helpers*/
.bg-img-circle{
  height: 20px !important;
  background:#f1f1f1;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8' width='4' height='4'%3E%3Cpath fill='%23fff' d='M0 0h8v8H0zm1 4a3 3 0 1 0 6 0 3 3 0 1 0-6 0m2.315 0a.685.685 0 1 0 1.37 0 .685.685 0 1 0-1.37 0'/%3E%3C/svg%3E")
}
/*End of Ruler helpers*/
.swiper-pagination{
  &.white{
      .swiper-pagination-bullet{
          background: #fff !important;
      }
  }
}
.swiper-pagination{
  &.purple{
      .swiper-pagination-bullet{
          background: #00A1AC !important;
      }
  }
}


.chatlio-offline-message-container{
  padding: 0px !important;
}

.opaque{
  .fi-map-marker-question{
    filter: grayscale(100%) !important;
  }
}