.rotating {
    display: inline-block;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(0) rotateY(0) rotateZ(0);
    -moz-transform: rotateX(0) rotateY(0) rotateZ(0);
    -ms-transform: rotateX(0) rotateY(0) rotateZ(0);
    -o-transform: rotateX(0) rotateY(0) rotateZ(0);
    transform: rotateX(0) rotateY(0) rotateZ(0);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform-origin-x: 50%;
  }
  
  .rotating.flip {
    position: relative;
  }
  
  .rotating .front, .rotating .back {
    left: 0;
    top: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .rotating .front {
    position: absolute;
    display: inline-block;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
    -ms-transform: translate3d(0,0,1px);
    -o-transform: translate3d(0,0,1px);
    transform: translate3d(0,0,1px);
  }
  
  .rotating.flip .front {
    z-index: 1;
  }
  
  .rotating .back {
    display: block;
    opacity: 0;
  }
  
  .rotating.spin {
    -webkit-transform: rotate(360deg) scale(0);
    -moz-transform: rotate(360deg) scale(0);
    -ms-transform: rotate(360deg) scale(0);
    -o-transform: rotate(360deg) scale(0);
    transform: rotate(360deg) scale(0);
  }
  
  
  
  .rotating.flip .back {
    z-index: 2;
    display: block;
    opacity: 1;
    
    -webkit-transform: rotateY(180deg) translate3d(0,0,0);
    -moz-transform: rotateY(180deg) translate3d(0,0,0);
    -ms-transform: rotateY(180deg) translate3d(0,0,0);
    -o-transform: rotateY(180deg) translate3d(0,0,0);
    transform: rotateY(180deg) translate3d(0,0,0);
  }
  
  .rotating.flip.up .back {
    -webkit-transform: rotateX(180deg) translate3d(0,0,0);
    -moz-transform: rotateX(180deg) translate3d(0,0,0);
    -ms-transform: rotateX(180deg) translate3d(0,0,0);
    -o-transform: rotateX(180deg) translate3d(0,0,0);
    transform: rotateX(180deg) translate3d(0,0,0);
  }
  
  .rotating.flip.cube .front {
    -webkit-transform: translate3d(0,0,100px) scale(0.9,0.9);
    -moz-transform: translate3d(0,0,100px) scale(0.85,0.85);
    -ms-transform: translate3d(0,0,100px) scale(0.85,0.85);
    -o-transform: translate3d(0,0,100px) scale(0.85,0.85);
    transform: translate3d(0,0,100px) scale(0.85,0.85);
  }
  
  .rotating.flip.cube .back {
    -webkit-transform: rotateY(180deg) translate3d(0,0,100px) scale(0.9,0.9);
    -moz-transform: rotateY(180deg) translate3d(0,0,100px) scale(0.85,0.85);
    -ms-transform: rotateY(180deg) translate3d(0,0,100px) scale(0.85,0.85);
    -o-transform: rotateY(180deg) translate3d(0,0,100px) scale(0.85,0.85);
    transform: rotateY(180deg) translate3d(0,0,100px) scale(0.85,0.85);
  }
  
  .rotating.flip.cube.up .back {
    -webkit-transform: rotateX(180deg) translate3d(0,0,100px) scale(0.9,0.9);
    -moz-transform: rotateX(180deg) translate3d(0,0,100px) scale(0.85,0.85);
    -ms-transform: rotateX(180deg) translate3d(0,0,100px) scale(0.85,0.85);
    -o-transform: rotateX(180deg) translate3d(0,0,100px) scale(0.85,0.85);
    transform: rotateX(180deg) translate3d(0,0,100px) scale(0.85,0.85);
  }