.fi-map-marker-me{
    width: 50px;
    height: 50px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

  }
  
  .fi-map-marker-endpoi{
    width: 50px;
    height: 50px;
    
    background: url(../../assets/images/pois/sh.png);
    background-size: 50px 50px;
    background-repeat: no-repeat;
  }

  .fi-map-marker-enemy{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    font-size: 1.5rem;
    &.bg-2{
      background-color: #FCBA1A;
    }
    &.bg-3{
      background-color: #B87771;
    }
    &.bg-4{
      background-color: #FFCDB6;
    }
  }