$flinkit-upper-section-height: 40%;

.flinkit-full-bg{
  background-color: $flinkit-base-color;
  width: 100%;
  height: 100%;
  &.white{
    background-color:white !important;
  }
}

/* .modal-dialog, .modal-content{
  height: calc(100% - 10px);
} */

.flinkit-logo-container{
  position: absolute;
  top:20px;
  left: 50%;
  width: 100px;
  margin-left: -50px;
  text-align: center;
  font-size: 1.4rem;
}

.fi-question-card{
  background: white;
  height: 100%;
  width: 100%;
}
.fi-trainer-player-tabs{
  h6{
    font-size: 14px;
  }
  .active{
    padding-bottom: 3px;
    border-bottom: 6px solid $flinkit-base-color;
  }
  .inactivePlayers{
    &::before{
      background-color: red;
      margin-right: 5px;
    }
  }
  .activePlayers{
    &::before{
      background-color: yellowgreen;;
      margin-right: 5px;
    }
  }
}

.fi-game-card{
  background-color: $flinkit-base-color;
  padding: 1.7px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  text-align: center;
  height: 200px;
  width: 150px;
  &.gray{
    background-color: #eaeaea;
  }
  &.disabled{
    opacity: 0.7;
  }
  &.bg-1{
    background-color: $flinkit-base-color;
  }
  &.bg-2{
    background-color: #FCBA1A;
  }
  &.bg-3{
    background-color: #B87771;
  }
  &.bg-4{
    background-color: #FFCDB6;
  }
}

.fi-players-list{
  padding-left: 0px;
  margin-left: 0px;
  list-style: none;
  li{
    padding: 10px 15px;
    font-size: 0.9rem;
    background: $flinkit-secondary-bg-color;
  }
}

.swiper-pagination-bullet-active{
  background: white;
}

.flinkit-inline-error-container{
  padding: 1rem;
  background: indianred;
  color: white;
  font-size: 0.8rem;
}

.fi-minifier-container{
  li, p{
    font-size: 0.8rem;
  }
}

.fi-border-radius-10{
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
}

.fi-border-radius-30{
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  border-radius: 30px !important;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid $flinkit-base-color;
}
.flinkit-new-page-content-section{
  .upper{
    background: $flinkit-base-color;
    .content{
      padding: 20px;
    }
    .flinkit-divider{
      margin-bottom: -2px;
      bottom: -3px;
      position: relative;
    }
  }
  .bottom{
    min-height: 350px;
  }
  swiper{
    width: 100%;
    height: auto;
    .swiper-slide{
      width: 50px;
      &.emojis{
        width: 70px;
        img{
          width: 60px;
        }
      }
      &.walkthrough{
        width: 100%;
        height: 180px !important;
      }
    }
    .swiper-slide-active{
      h1{
        font-size: 4rem !important;
      }
    }
  }
}
.flinkit-section-bg{
  height: 100%;
  /*.flinkit-upper-section{
    .flinkit-logo-container{
      top:10px;
    }
    height: $flinkit-upper-section-height;
    background: $flinkit-base-color;
    width: 100%;
    position: relative;
    .flinkit-divider{
      position: absolute;
      bottom:-1px;
      width: 100%;
    }
  }*/
  .flinkit-page-content-section{
    height: 100%;
    width: 100%;
    small{
      font-size: 0.7rem;
    }
    .inner-container{
      margin-top: -30px;
    }
    swiper{
      width: 100%;
      height: auto;
      .swiper-slide{
        width: 50px;
        &.emojis{
          width: 70px;
          img{
            width: 60px;
          }
        }
        &.walkthrough{
          width: 100%;
          height: 180px !important;
        }
      }
      .swiper-slide-active{
        h1{
          font-size: 4rem !important;
        }
      }
    }
  }
}

.flinkit-pin-code-container{
  .pin-input-container{
    width: 80px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    color: black;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: white;
    &.dark{
      //background-color: black;
      input{
        color: greenyellow !important;
      }
    }
    &.narrow{
      width: 30%;
    }
    input{
      border: none !important;
      background: transparent;
      width: 100%;
      height: 120px;
      text-align: center;
      color: black;
      font-weight: bold;
      &::placeholder{
        font-weight: bold;
        color: gray;
      }
      outline: none;
    }
    &.trainer{
      width: 60px;
      height: 80px;
      line-height: 80px;
      font-size: 2rem;
      input{
        height: 80px;
      }
    }
  }
}

.ngx-slider .ngx-slider-pointer {
  
  background-color: #4e3b74 !important;
}
.fi-result-row{
  background: white;
  border-radius: 20px;
}

.swal2-styled {
  margin: .3125em;
  padding: 10px 30px;
  box-shadow: none;
  font-weight: 500;
  border-radius: 40px !important;
  font-family: "Airbnb-Bold";
}

.pt-7-rex{
  padding-top: 7rem;
}
.pb-5-rex{
  padding-bottom: 7rem;
}