
#joinAbsolute{
    position: fixed;
    bottom:20px;
    left: 50%;
    margin-left: -125px;
    width: 250px;
    z-index: 999;
border:3px solid black;
}


@media only screen and (min-width: 0px) and (max-width: 767px)  {
    .text-bold-xs{
        font-weight: bold;
    }
    .fl-main-cover{
        height: 75%;
    }
    .justify-content-center-custom{
        justify-content: center;
    }
    .opaque-circle{
        opacity: 0.8;
        background-image: url(../../assets/images/bl.png);
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 90%;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 0;
    }
}

.z-index-999{
    z-index: 999;
}

@media (min-width: 768px) {  
    .text-bold-xs{
        font-weight: initial;
    }
    .fl-main-cover{
        height: 50%;
    }
    .fi-main-logo{
        position: absolute;
        top:20px;
        left:20px;
    }
    .custom-cta{
        min-height: 300px;
        width: 100%;
    }
    .justify-content-center-custom{
        justify-content: center;
    }
    
}

.time-icon{
    width: 100px;
    position: absolute;
    top: 0px;
    right:-20px;
    z-index: 999;
}

.fi-testimonial-card{

    height: 340px;
    border-radius: 10px;
}

.fl-logo-absolute{
    position: absolute;
    z-index: 999;
    top:20px;
    left: 20px;
    img{
        width: 150px;
    }
}
.fl-desktop-container{
    max-width: 700px;
    .fi-text-small{
        font-size: 0.8rem;
    }
    .inner{
        background: white;
        border-radius: 10px;
        z-index: 999;
        .watch-icon{
            position: absolute;
            left: 50%;
            margin-left: -20px;
            bottom:-20px;
        }
        .phone-icon{
            position: absolute;
            right: -160px;
            width: 360px;
            top: 30px;
        }
        .phone-icon-2{
            position: absolute;
            right: -160px;
            width: 290px;
            top: 30px;
        }
        .phone-icon-3{
            position: absolute;
            right: -220px;
            width: 390px;
            top: 0px;
        }
    }
    .outer{
        background: white;
        opacity: 0.6;
        border-radius: 10px;
        margin-top: -20px;
        padding-top: 60px;
        height: 180px;
        position: relative;
    }
    .content{
        text-align: center;
        bottom: 35px;
        position: absolute;
        width: 100%;
        .fi-desktop-footer{
            font-size: 0.7rem;
            a{
                color: black;  
            }
        }
    }
  
}
.max-w160{
    width: 100%;
    max-width: 160px;
}
.text-light-color{
    color: #637aa1;
}
.fl-hero-game-title{
    color: #77edff;
}
.color-orange{
    color: orange;
}
.font-size-12{
    font-size: 18px;
}
.fl-landing-gradient{
    background: rgb(119,237,255);
    background: linear-gradient(180deg, rgba(119,237,255,1) 0%, rgba(0,212,255,0) 100%);
}
.fl-btn{
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    padding: 5px 20px !important;
    font-weight: bold;
    border:3px solid #77edff;
    background-color: #77edff;
    color: black;
}
.fl-mobile-landing-header{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100px;
    background-color: rgba(255,255,255,0.6);
}
.pattern-bg{
    background: url(../../assets/images/ver2/pattern.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    &.first{
        padding-top: 150px;
    }
    &.plain{
        background: url(../../assets/images/ver2/pattern2.png);
    }
    &.plain2{
        background: #253863;
    }
    .dollar-ext{
        position: absolute;
        width: 320px;
        left: 50%;
        margin-left: -160px;
        top:-20px;
        height: 125px;
        z-index: 999;
    }
    .patter-ext{
        position: absolute;
        width: 100%;
        left: 0px;
        top:0px;
        height: 50px;
        &.green{
            top:-40px
        }
    }
}
.fl-game-container{
    
  
   
    height: 550px;
    &.reverse{
        
        height: 550px;
    }
}

@media (max-width: 576px) {  
    .mobile-img{
        width: 200px;
    }
    .fl-landing-image{
     align-items: center;
     justify-content: center;
    }
    .fl-game-container{
        background: url(../../assets/images/ver2/bgm.png);
        background-size: cover;
        &.reverse{
            background: url(../../assets/images/ver2/bgm2.png);
            background-size: cover;
            height: 650px;
        }
    }
}

@media (min-width: 576px) {  
    .fl-landing-image{
        align-items: center;
        justify-content: flex-end;
    }
    .mobile-img{
        width: 300px;;
    }
    .fl-game-container{
        background: url(../../assets/images/ver2/cover.png);
        background-size: contain;
        background-repeat: no-repeat;
        &.reverse{
            margin-top: -100px;
         
            background: url(../../assets/images/ver2/cover1.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position-x:right;
        }
    }
}
