@import "~line-awesome/dist/line-awesome/css/line-awesome.min.css";
@import "assets/styles/swiper";
@import "assets/styles/variables";
@import "assets/styles/elements";
@import "assets/styles/buttons";
@import "assets/styles/landing";
@import "assets/styles/inputs";
@import "assets/styles/typo";
@import "assets/styles/helpers";
@import "assets/styles/trainer";
@import "assets/styles/map";
@import "assets/styles/chat";
@import "assets/styles/rotator";
@import "~sweetalert2/src/sweetalert2.scss";
@import "~noty/src/noty.scss";
@import "~noty/src/themes/mint.scss";

undefined {
  flex: 1;
}

body.swal2-height-auto{
  display: contents !important;
}

select {
  outline: 0px !important;
}

::ng-deep *:focus {
  outline: none !important;
  outline: 0 !important;
}

body, html {
  height: 100% !important;
  font-family: 'Airbnb-Light', Arial, "Lucida Grande", sans-serif;
  width: 100%;
}

body.modal-open{
  transform: none !important;
}

flinkit-start-modal, flinkit-error-modal, flinkit-chat-modal, flinkit-pin-code, flinkit-perks-modal, flinkit-perks-modal, flinkit-freeze-modal, flinkit-qr-modal, flinkit-ar-modal {
  height: 100% !important;
}
flinkit-ar-modal{
  position: relative !important;
}

zxing-scanner, video{
  height: 100% !important;
}

#arjs-video{
  position: relative !important;
  z-index: 999 !important;
  margin-left: initial !important;
}

.mapboxgl-marker{
  &.fi-position-top{
    z-index: 99 !important;
  }
  &.fi-position-middle{
    z-index: 9 !important;
  }
}


.noty_layout_mixin, #noty_layout__centerRight, #noty_layout__centerLeft, #noty_layout__center, #noty_layout__bottomRight, #noty_layout__bottomCenter, #noty_layout__bottomLeft, #noty_layout__bottom, #noty_layout__topRight, #noty_layout__topCenter, #noty_layout__topLeft, #noty_layout__top{
  z-index: 999999 !important;
}

.noty_type__white{
  background: white;
}

router-outlet {
  display: flex;
  flex-flow: column;
}

@font-face {
  font-family: "Airbnb-Light";
  src: url("assets/fonts/AirbnbCerealLight.ttf") format("truetype");
}

@font-face {
  font-family: "Airbnb-Book";
  src: url("assets/fonts/AirbnbCerealBook.ttf") format("truetype");
}

@font-face {
  font-family: "Airbnb-Medium";
  src: url("assets/fonts/AirbnbCerealMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Airbnb-Bold";
  src: url("assets/fonts/AirbnbCerealBold.ttf") format("truetype");
}

@font-face {
  font-family: "Airbnb-Black";
  src: url("assets/fonts/AirbnbCerealBlack.ttf") format("truetype");
}





