
flinkit-map-modal, #fi-trainer-map{
  height: 100%;
  width: 100%;
}
#fi-map-close-fab{
  position: fixed;
  bottom: 40px;
  left: 50%;
  width: 50px;
  height: 50px;
  background: white;
  text-align: center;
  line-height: 50px;
  margin-left: -25px;
  border-radius: 50%;
  z-index: 999 !important;
}
