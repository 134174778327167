.flinkit-text-small{
  font-size: 0.8rem;
}
.fi-text-black{
  font-family: "Airbnb-Black";
}
.fi-text-bold{
  font-family: "Airbnb-Bold";
}
.fi-text-normal{
  font-family: "Airbnb-Book";
}
.fi-text-medium{
  font-family: "Airbnb-Medium";
}
.fi-text-light{
  font-family: "Airbnb-Light";
}
.fi-text-huge{
  font-size: 4rem;
}
.fi-text-08{
  font-size: 0.8rem !important;
}
.fi-text-09{
  font-size: 0.9rem !important;
}
.fi-text-huge-2{
  font-size: 3rem !important;
}
.fi-text-gray{
  color: gray;
}
.flinkit-event-text{
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 1px;
  color: lightgrey;
}
.fs-1-3{
  font-size: 1.3rem !important;
}
.fs-stroke{
  -webkit-text-stroke: 1px black;
}

@media screen and (max-width: 1290px) {
  .max-300{
    max-width: 300px !important;
  }
}
@media screen and (min-width: 1291px) {
  .max-300{
    max-width: 300px !important;
  }
}



.fi-text-primary{
  color: $flinkit-base-color;
}
.fi-text-lightblue{
  color: #77edff;
}
.fi-text-vivid{
  color: #00606f;
}
.fl-desktop-footer{
  position: absolute;
  left: 0px;
  bottom:0px;
  width: 100%;
  div{
    a{
      color: gray;
    }
  }
}
