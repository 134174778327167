.noty_theme__mint.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  border-radius: 2px;
  position: relative;

  .noty_body {
	padding: 10px;
	font-size: 14px;
  }

  .noty_buttons {
	padding: 10px;
  }
}

.noty_theme__mint.noty_type__alert,
.noty_theme__mint.noty_type__notification {
  background-color: #fff;
  border-bottom: 1px solid #D1D1D1;
  color: #2F2F2F;
}

.noty_theme__mint.noty_type__warning {
  background-color: #FFAE42;
  border-bottom: 1px solid #E89F3C;
  color: #fff;
}

.noty_theme__mint.noty_type__error {
  background-color: #DE636F;
  border-bottom: 1px solid #CA5A65;
  color: #fff;
}

.noty_theme__mint.noty_type__info,
.noty_theme__mint.noty_type__information {
  background-color: #7F7EFF;
  border-bottom: 1px solid #7473E8;
  color: #fff;
}

.noty_theme__mint.noty_type__success {
  background-color: #AFC765;
  border-bottom: 1px solid #A0B55C;
  color: #fff;
}
